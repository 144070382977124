// tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base
{
	:root
	{
		--tw-color-primary: #035292;
		--tw-color-secondary: #629bcb;
		--tw-color-accent: #FF4886;
		--tw-color-warn: #FF595A;
	}
}

// node modules
@import "@angular/material/prebuilt-themes/indigo-pink.css";

// custom
@import "webapp.scss";
@import "custom.scss";
