.mdc-button.fancy-button {
    position: relative;
    overflow: hidden;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 16px;

    mat-icon {
        height: auto;
        width: auto;
        font-size: 18px;
    }

    &.background-icon mat-icon {
        position: absolute;
        bottom: -20px;
        right: 0;
        font-size: 70px;
        line-height: 70px;
        opacity: .3;
    }
}

.languages-list {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center;

    .language-item {

        img {
            border-radius: 2px;
        }
    }
}

.pc-form-filters {
    /*padding-bottom: 1rem;
    border-bottom: 4px solid #e2ebf5;*/

    .mat-form-field-label {
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        top: 1.5em;
    }

    .form-group {
        margin: 0;
    }

    .buttons-group {
        display: flex;
        padding-top: 15px;

        button {
            margin: 0 15px 0 0;
            align-self: center;

            > i {
                margin-right: 10px;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

// custom svg icon
.custom-svg-icon {
    height: 24px;
    width: 24px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /*background-image: url(svg code);*/
}

.specialty-pill,
.specialty-dot {

    &#revision {
        background: #4487eb;
    }

    &#salvage {
        background: #f34263;
    }

    &#oncology {
        background: #d642f1;
    }

    &#infection {
        background: #39b570;
    }

    &#primary {
        background: #ec4899;
    }

    &#other {
        background: #999;
    }
}

.reconstruction-area-selector {
    display: flex;
    align-items: center;

    .skeleton-wrapper {
        position: relative;
        width: 100%;
        height: 550px;
        margin: 0 0 1rem;

        .skeleton-container {
            width: 400px;
            height: 550px;
            padding: 1rem 0;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);

            > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: drop-shadow(0 4px 10px #00000088);
            }

            .mat-button-toggle-group.area-markers-container {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                overflow: visible;
                border: none;

                .mat-button-toggle.area-marker {
                    --area-marker-color: #888;
                    position: absolute;
                    border-radius: 100px;
                    font-weight: bold;
                    background: none;
                    border: none;
                    opacity: 1;
                    transition: all ease 0.4s;
                    /*overflow: hidden;*/

                    .mat-button-toggle-label-content {
                        padding: 0;
                    }

                    .mat-button-toggle-ripple {
                        border-radius: 100px;
                    }

                    .marker-box {
                        position: absolute;
                        content: '';
                        border: 3px dashed var(--area-marker-color);
                        width: 40px;
                        height: 40px;
                        border-radius: 50px;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%) rotate(0deg);
                        transition: all ease 0.4s;

                        &:before {
                            content: "";
                            position: absolute;
                            width: 40px;
                            height: 40px;
                            left: -2px;
                            top: -2px;
                            border-radius: 50px;
                            content: '';
                            background: var(--area-marker-color);
                            opacity: 0.2;
                            transition: all ease 0.4s;
                        }
                    }

                    .marker-badge {
                        position: relative;
                        background-color: var(--area-marker-color);
                        color: #fff;
                        border-radius: 0 50px 50px 0;
                        padding: 4px 10px;
                        margin-left: 40px;
                        transition: all ease 0.4s;
                        box-shadow: -20px 4px 30px -5px rgba(0,0,0,0.7);
                        z-index: -1;

                        &:before {
                            content: "";
                            position: absolute;
                            left: -22px;
                            bottom: 0;
                            top: 0;
                            width: 20px;
                            border-radius: 8px;
                            background-color: transparent;
                            box-shadow: 10px 0px 0 0 var(--area-marker-color);
                            /*z-index: -1;*/
                            transition: box-shadow ease 0.4s;
                        }
                    }

                    &#hip {
                        top: 244px;
                        left: 202px;
                    }

                    &#knee {
                        top: 362px;
                        left: 208px;
                    }

                    &#shoulder {
                        top: 108px;
                        left: 230px;
                    }

                    &#elbow {
                        top: 175px;
                        left: 252px;
                    }

                    &#ankle {
                        top: 467px;
                        left: 208px;
                    }

                    &#other {
                        top: 30px;
                        left: 230px;
                    }
                    /* checked */
                    &.mat-button-toggle-checked {
                        --area-marker-color: #FF4886;

                        .marker-box {
                            animation: marker-spin 6s linear infinite;

                            &:before {
                                opacity: 0.3;
                            }
                        }
                    }
                    /* disabled */
                    &.mat-button-toggle-disabled {
                        .mat-button-toggle-button {
                            background-color: transparent !important;
                        }

                        &:not(.mat-button-toggle-checked) {
                            display: none;
                        }
                    }

                    @keyframes marker-spin {
                        0% {
                            transform: translateY(-50%) rotate(0deg);
                        }

                        50% {
                            transform: translateY(-50%) rotate(180deg);
                        }

                        100% {
                            transform: translateY(-50%) rotate(360deg);
                        }
                    }
                }

                &:has(.mat-button-toggle.area-marker.mat-button-toggle-checked) .mat-button-toggle.area-marker:not(.mat-button-toggle-checked) {
                    opacity: 0.5;
                }
            }
        }
    }
}

.selected-reconstruction-area-image {
    flex-shrink: 0;

    .skeleton-wrapper {
        padding: 1rem;
        display: flex;
        align-items: center;
        height: 100%;

        > img {
            width: 45px;
            filter: grayscale(1);
            // MD Breakpoint
            @media (min-width: 768px) {
                width: 80px;
            }
            // LG Breakpoint
            @media (min-width: 1024px) {
                width: 110px;
            }
        }
    }
}

.selected-area-name {
    display: block;
    font-family: 'Poppins';
    font-size: 1.2rem;
    letter-spacing: 1px;
    font-weight: bold;
    color: $color-deep-space-blue;
    padding-bottom: 0.5rem;
}

.subject-title {
    display: block;
    font-family: 'Poppins';
    font-size: 1.2rem;
    font-weight: 500;
    color: $color-deep-space-blue;
    padding-bottom: 0.5rem;
}

.info-pill {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    border-bottom: 2px solid #ccc;
    background: #e6ecef;
    flex-grow: 1;

    .info-label {
        font-size: .8rem;
        color: #777;
        font-weight: bold;
    }

    .info-value {
        font-family: 'Poppins';
        font-size: .8rem;
        color: $color-deep-space-blue;
    }
}

.mat-mdc-chip-listbox {
    .mat-mdc-chip {
        &.mat-mdc-chip-selected {
            --mdc-chip-elevated-container-color: #FF4886;
            --mdc-chip-elevated-disabled-container-color: #FF4886;
        }
    }

    &.specialties-listbox {
        .mat-mdc-chip {
            &.mat-mdc-chip-selected {

                &.revision-color {
                    --mdc-chip-elevated-container-color: #4487eb;
                    --mdc-chip-elevated-disabled-container-color: #4487eb;
                }

                &.salvage-color {
                    --mdc-chip-elevated-container-color: #f34263;
                    --mdc-chip-elevated-disabled-container-color: #f34263;
                }

                &.oncology-color {
                    --mdc-chip-elevated-container-color: #d642f1;
                    --mdc-chip-elevated-disabled-container-color: #d642f1;
                }

                &.infection-color {
                    --mdc-chip-elevated-container-color: #39b570;
                    --mdc-chip-elevated-disabled-container-color: #39b570;
                }

                &.primary-color {
                    --mdc-chip-elevated-container-color: #ec4899;
                    --mdc-chip-elevated-disabled-container-color: #ec4899;
                }

                &.other-color {
                    --mdc-chip-elevated-container-color: #999;
                    --mdc-chip-elevated-disabled-container-color: #999;
                }
            }

            &.mat-mdc-chip-disabled {
                opacity: 1;

                &:not(.mat-mdc-chip-selected) {
                    display: none;
                }
            }
        }
    }
}

.skeleton-wrapper {
    border-radius: 10px;
    background-color: #222f3c;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%2391a6bb' fill-opacity='0.4'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.skeleton-wrapper:not(.scanner-animation-disabled),
.bone-wrapper:not(.scanner-animation-disabled) {
    overflow: hidden;

    &:after {
        content: '';
        position: absolute;
        background: linear-gradient(90deg, transparent 0%,#e5ffec22 55%, #bdffd277 98%, #bdffd299);
        top: 0;
        bottom: 0;
        left: -80px;
        transform: scaleX(1);
        width: 80px;
        opacity: 0.85;
        pointer-events: none;
        animation: 18s linear 3s infinite skeleton-scanner;
    }
}

.scanner-animated-item {

    .bone-wrapper:after {
        transform: scaleX(-1);
        opacity: 0.7;
        animation: none;
        transition: left .7s linear;
    }

    &:hover {
        .bone-wrapper:after {
            left: 100%;
            transform: scaleX(1);
        }
    }
}

@keyframes skeleton-scanner {
    /*going RIGHT*/
    0% {
        left: -80px;
        transform: scaleX(1);
    }

    /*reached RIGHT*/
    15% {
        left: 100%;
        transform: scaleX(1);
    }
    /*reverse to LEFT*/
    20% {
        left: 100%;
        transform: scaleX(-1);
    }

    /*reached LEFT*/
    34% {
        left: -80px;
        transform: scaleX(-1);
    }
    /*reverse back to RIGHT*/
    35% {
        left: -80px;
        transform: scaleX(1);
    }

    /*pause*/
    100% {
    }
}

.page-actions {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding: 2rem 0 1.5rem;
    margin-top: 2rem;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(90deg, transparent, #a9c9e3, transparent);
    }
}
