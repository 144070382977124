﻿@use '@angular/material' as mat;
@import '@angular/material/theming';

/* =========================================
	SETUP AND CUSTOMIZATION
	change base colors to customize theme
========================================== */

$pcode-color-primary: #035292!default;
$pcode-color-secondary: #629bcb!default;
$pcode-color-accent: #FF4886!default;
$pcode-color-warn: #FF595A!default;

/* =========================================
	THEME VARIABLES
========================================== */

/* fonts */
$font-family-header: 'Poppins', sans-serif!default;
$font-family-body: 'Open Sans', sans-serif!default;

/* globals */
$text_color: #607188;
$header_text_color: #516073;

/* colors */
$color-blue: #0099D6;
$color-dark-blue: #235da3;
$color-silver-gray: #f8f9fa;
$color-light-gray: #dee2e6;
$color-gray: #b2b5b7;
$color-dark-gray: #8d9092;
$color-black: #212529;
$color-light-black: #676767;
$color-deep-space-blue: #4b5b6d;
$color-accent: $pcode-color-accent;
$color-primary: $pcode-color-primary;
$color-secondary: $pcode-color-secondary;
$color-success: #2fb431;
$color-warning: #f3aa2d;
$color-danger: $pcode-color-warn;

/* font size */
$font-size-default: 15px;

@mixin box-shadow-inset-small
{
	box-shadow: inset 0 0 10px rgb(0 0 0 / 5%);
}

@mixin box-shadow-small
{
	box-shadow: 0 0 10px rgb(0 0 0 / 5%);
}

@mixin gradient-background
{
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#49c0cf+0,32a0f4+100 */
	background: #49c0cf; /* Old browsers */
	background: -moz-linear-gradient(top, #49c0cf 0%, #32a0f4 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #49c0cf 0%,#32a0f4 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #49c0cf 0%,#32a0f4 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#49c0cf', endColorstr='#32a0f4',GradientType=0 ); /* IE6-9 */
}

@mixin border-small
{
	border: 2px solid rgb(0 0 0 / 10%);
}

@mixin border-radius-10
{
	-webkit-border-radius: 10px;
	border-radius: 10px;
}

@mixin border-radius-100
{
	-webkit-border-radius: 100px;
	border-radius: 100px;
}

@mixin border-radius-50p
{
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

@mixin default-transition
{
	-webkit-transition: all 200ms linear;
	-moz-transition: all 200ms linear;
	-o-transition: all 200ms linear;
	transition: all 200ms linear;
}

@mixin rotating
{
	-webkit-animation: rotating 2s linear infinite;
	-moz-animation: rotating 2s linear infinite;
	-o-animation: rotating 2s linear infinite;
	animation: rotating 2s linear infinite;
}

@mixin text-shadow-light
{
	text-shadow: 0px 0px 5px rgb(0 0 0 / 20%);
}

@mixin gray-scale
{
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
	filter: grayscale(100%);
}

@mixin no-filters
{
	-webkit-filter: none;
	filter: none;
}

@mixin ellipsis
{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin blink($frequency)
{
	animation: blink-animation #{$frequency}s steps(5, start) infinite;
	-webkit-animation: blink-animation #{$frequency}s steps(5, start) infinite;
}

@mixin noselect
{
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@mixin text-gradient-primary {
	color: $color-primary;
	background-image: -webkit-linear-gradient(135deg, $color-secondary, $color-primary);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

@keyframes blink-animation
{
	to
	{
		visibility: hidden;
	}
}

@-webkit-keyframes blink-animation
{
	to
	{
		visibility: hidden;
	}
}

@-webkit-keyframes rotating /* Safari and Chrome */
{
	from
	{
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to
	{
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes rotating
{
	from
	{
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to
	{
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}